import './Main.css';

function Main() {
  return (
    <div className="portfolio-grid">
      <div className="portfolio-item">
        <a
          href="https://gabyisabelle.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="portfolio-img"
            src="./images/portfolio-1.jpg"
            alt="Portfolio Item 1"
          />
          <div className="overlay">
            <span className="overlay-text">Gaby Photography</span>
          </div>
        </a>
      </div>
      <div className="portfolio-item">
        <a href="https://bumbo.com/" target="_blank" rel="noopener noreferrer">
          <img
            className="portfolio-img"
            src="./images/portfolio-2.jpg"
            alt="Portfolio Item 2"
          />
          <div className="overlay">
            <span className="overlay-text">Bumbo</span>
          </div>
        </a>
      </div>
      <div className="portfolio-item">
        <a
          href="https://purrfection.vercel.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="portfolio-img"
            src="./images/portfolio-3.jpg"
            alt="Portfolio Item 3"
          />
          <div className="overlay">
            <span className="overlay-text">Purrfection (artist)</span>
          </div>
        </a>
      </div>
      <div className="portfolio-item">
        <a
          href="https://book-store-snowy-seven.vercel.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="portfolio-img"
            src="./images/portfolio-4.jpg"
            alt="Portfolio Item 4"
          />
          <div className="overlay">
            <span className="overlay-text">Urban Eagle Books</span>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Main;
