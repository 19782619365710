import React, { useState } from 'react';
import './Contact.css';
import Modal from './Modal';

function Contact() {
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="contact-container">
      <div className="profile-pic-container">
        <img
          className="profile-pic"
          src="./images/profile-pic-new.jpg"
          alt="Profile"
        />
      </div>

      <div className="contact-blocks">
        <div className="left-contact">
          <p className="skills-header">Areas of expertise</p>
          <p className="skill">Full Stack Web Development</p>
          <p className="skill">Web Hosting / Deployment</p>
        </div>
        <div className="right-contact">
          <p>
            After working in accounting for almost 6 years, I gravitated toward
            the software industry purely out of interest in such a vast world of
            technology and the obvious continuous growth that goes hand in hand
            with it. I studied full-stack web development at Hyperion
            Development where I learned Python, Javascript, CSS, and HTML along
            with full-stack web development frameworks such as the MERN stack
            (MongoDB, Express, React, NodeJS).
          </p>
          <p>
            I have worked in the online programming education sector part-time
            for the last 5 years as well as in the development industry,
            predominantly as a frontend web developer, full-time for 3 years
            (Vue.js, C#, SCSS/CSS, Javascript, HTML, etc.). I'm efficient in
            both a remote and an on-site capacity and I'm a curious learner,
            keen to take on new challenges.
          </p>
          <div className="contact-details">
            <div className="email">
              <p className="contact-label">Email</p>
              <p>joshuawiidrichter@gmail.com</p>
            </div>
            <div className="phone">
              <p className="contact-label">Phone</p>
              <p>+(27) 71 136 5404</p>
            </div>
          </div>
          <button
            className="contact-btn"
            type="button"
            onClick={handleModalOpen}
          >
            Or reach out here!
          </button>
        </div>
      </div>
      <Modal show={showModal} onClose={handleModalClose} />
    </div>
  );
}

export default Contact;
