import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <a
        href="https://www.linkedin.com/in/joshua-richter-5373329b/"
        target="_blank"
      >
        <img className="socials-img" src="./images/linkedin.png" />
      </a>
    </div>
  );
}

export default Footer;
