import React, { useState } from 'react';
import './Modal.css';

function Modal({ show, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState(''); // 'success' or 'error'

  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSend = {
      name: formData.name,
      lastName: formData.lastName,
      email: formData.email,
      message: formData.message,
    };

    fetch('https://portfolio-web-murex-seven.vercel.app/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((data) => {
        setStatusType('success');
        setStatusMessage(
          'Your message has been sent. I will get back to you soon!'
        );
        setFormData({ name: '', lastName: '', email: '', message: '' }); // Clear form
      })
      .catch((error) => {
        setStatusType('error');
        setStatusMessage(
          'Something went wrong. Please try sending the email later or reach out with the provided email address.'
        );
      });
  };

  const resetModal = () => {
    setStatusMessage('');
    setStatusType('');
  };

  const handleReopen = () => {
    resetModal(); // Clear status message when reopening
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        {statusMessage ? (
          <div className={`status-message ${statusType}`}>
            <p>{statusMessage}</p>
            <button className="close-btn" onClick={onClose}>
              Close
            </button>
          </div>
        ) : (
          <form className="modal-form" onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Email Address:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Message / Enquiry:
              <textarea
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </label>
            <button type="submit" className="submit-btn">
              Submit
            </button>
            <button className="close-btn" onClick={onClose}>
              Close
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Modal;
