import './Header.css';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className="header-container">
      <div className="header-left">
        <h2>Joshua Richter</h2>
      </div>
      <div className="header-right">
        <ul>
          <li>
            <Link className="nav-text" to="/">
              Work
            </Link>
          </li>
          <li>
            <Link className="nav-text" to="/contact">
              Contact
            </Link>
          </li>
        </ul>

        <a
          href="https://www.linkedin.com/in/joshua-richter-5373329b/"
          target="_blank"
        >
          <img className="socials-icon" src="/images/linkedin.png" />
        </a>
      </div>
    </div>
  );
}

export default Header;
